import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import {store, persistor} from "./store";
import  TagManager from "react-gtm-module";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const tagManagerArgsWeb = {
  gtmId:'GTM-NZRTJC'
}

const tagManagerArgsAd = {
  gtmId:'GTM-ML57FLPX'
}

const tagManagerArgsJpReg = {
  gtmId:'GTM-PDXBB9T'
}

TagManager.initialize(tagManagerArgsWeb);
TagManager.initialize(tagManagerArgsAd);
TagManager.initialize(tagManagerArgsJpReg);

root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
