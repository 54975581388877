import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJson from "./utils/locale/en.json";
import thJson from "./utils/locale/th.json";
import zhJson from "./utils/locale/zh.json";
import arJson from "./utils/locale/ar.json";
import deJson from "./utils/locale/de.json";
import esJson from "./utils/locale/es.json";
import frJson from "./utils/locale/fr.json";
import itJson from "./utils/locale/it.json";
import jpJson from "./utils/locale/jp.json";
import koJson from "./utils/locale/ko.json";
import ruJson from "./utils/locale/ru.json";
import svJson from "./utils/locale/sv.json";
import trJson from "./utils/locale/tr.json";
import twJson from "./utils/locale/tw.json";
import vnJson from "./utils/locale/vn.json";
import hkJson from "./utils/locale/hk.json";

const langDetector = new LanguageDetector();
langDetector.addDetector({
  name: "customPathDetector",
  lookup(options) {
    let found;
    if (typeof window !== "undefined") {
      const language = window?.location?.pathname?.match(/\/([a-zA-Z-]*)/g);
      if (language instanceof Array) {
        if (typeof options?.lookupFromPathIndex === "number") {
          if (typeof language[options?.lookupFromPathIndex] !== "string") {
            return undefined;
          }
          found = language[options.lookupFromPathIndex].replace("/", "");
        } else {
          found = language[0].replace("/", "");
        }
      }
    }
    return found;
  },
  cacheUserLanguage(lng, options) {},
});
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(langDetector)
  .init({
    resources: {
      "en-th": { ...enJson },
      "th-th": { ...thJson },
      "en-au": { ...enJson },
      "en-bd": { ...enJson },
      "en-KH": { ...enJson },
      "en-cn": { ...enJson },
      "zh-cn": { ...zhJson },
      "en-in": { ...enJson },
      "en-id": { ...enJson },
      "en-jp": { ...enJson },
      "ja-jp": { ...jpJson },
      "en-kr": { ...enJson },
      "ko-kr": { ...koJson },
      "en-la": { ...enJson },
      "en-my": { ...enJson },
      "en-mm": { ...enJson },
      "en-np": { ...enJson },
      "en-nz": { ...enJson },
      "en-pk": { ...enJson },
      "en-ph": { ...enJson },
      "en-sg": { ...enJson },
      "en-lk": { ...enJson },
      "en-tw": { ...enJson },
      "zh-tw": { ...twJson },
      "en-vn": { ...enJson },
      "vn-vn": { ...vnJson },
      "en-hk": { ...enJson },
      "en-be": { ...enJson },
      "en-dk": { ...enJson },
      "en-fr": { ...enJson },
      "fr-fr": { ...frJson },
      "en-de": { ...enJson },
      "de-de": { ...deJson },
      "en-it": { ...enJson },
      "it-it": { ...itJson },
      "en-no": { ...enJson },
      "ru-ru": { ...ruJson },
      "en-es": { ...enJson },
      "es-es": { ...esJson },
      "sv-se": { ...svJson },
      "en-ch": { ...enJson },
      "fr-ch": { ...frJson },
      "de-ch": { ...deJson },
      "en-tr": { ...enJson },
      "tr-tr": { ...trJson },
      "en-gb": { ...enJson },
      "en-sa": { ...enJson },
      "ar-sa": { ...arJson },
      "en-ae": { ...enJson },
      "en-ca": { ...enJson },
      "en-us": { ...enJson },
      "en-ru": { ...enJson },
      "tw-tw": { ...twJson },
      "tw-hk": { ...hkJson },
      "zh-hk": { ...zhJson },
    },
    supportedLngs: [
      "en-th",
      "th-th",
      "en-au",
      "en-bd",
      "en-kh",
      "en-cn",
      "zh-cn",
      "en-in",
      "en-id",
      "en-jp",
      "ja-jp",
      "en-kr",
      "ko-kr",
      "en-la",
      "en-my",
      "en-mm",
      "en-np",
      "en-nz",
      "en-pk",
      "en-ph",
      "en-sg",
      "en-lk",
      "en-tw",
      "zh-tw",
      "en-vn",
      "vn-vn",
      "en-hk",
      "zh-hk",
      "en-be",
      "en-dk",
      "en-fr",
      "fr-fr",
      "en-de",
      "de-de",
      "en-it",
      "it-it",
      "en-no",
      "en-ru",
      "ru-ru",
      "en-es",
      "es-es",
      "en-se",
      "sv-se",
      "en-ch",
      "fr-ch",
      "de-ch",
      "en-tr",
      "tr-tr",
      "en-gb",
      "en-sa",
      "ar-sa",
      "en-ae",
      "en-ca",
      "en-us",
      "tw-tw",
      "tw-hk",
    ],
    fallbackLng: "index",
    lowerCaseLng: true,
    detection: {
      order: ["customPathDetector", "cookie"],
      lookupFromPathIndex: 0,
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
